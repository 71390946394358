// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-game-info-js": () => import("/opt/build/repo/src/templates/game-info.js" /* webpackChunkName: "component---src-templates-game-info-js" */),
  "component---src-templates-browse-js": () => import("/opt/build/repo/src/templates/browse.js" /* webpackChunkName: "component---src-templates-browse-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-elements-js": () => import("/opt/build/repo/src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("/opt/build/repo/src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-how-it-works-js": () => import("/opt/build/repo/src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("/opt/build/repo/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-office-js": () => import("/opt/build/repo/src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-restaurant-js": () => import("/opt/build/repo/src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */)
}

